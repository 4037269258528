<template>
    <div class="shipping-box">

        <h1 class="head">Checkout</h1>

        <div class="shipping-box-main">
                
            <div class="shipping-details">

                <div class="shipping-instruction">
                    <h1>SHIPPING DETAILS</h1>
                    <textarea id="w3review" name="w3review" rows="4" cols="50"></textarea>
                </div>

                <div class="delivery-instruction">
                    <h1>Delivery Instructions</h1>
                    <textarea id="w3review" name="w3review" rows="4" cols="50"></textarea>
                </div>
                <hr>
                <div class="billing-details">
                    <h1>BILLING DETAIL</h1>
                    <Checkbox
                        label="Same as shipping details" />
                </div>
                <div class="continue-btn">
                    <Button 
                        primary
                        name="Continue" />
                </div>
            </div>

            <PriceDetails />

        </div>

    </div>
</template>

<script>
import Checkbox from 'componentsv2/Checkbox'
import Button from 'componentsv2/Button'
import PriceDetails from 'componentsv2/PriceDetails'

export default {
    name: 'ShippingDetailsPage',
    components: {
        Checkbox,
        Button,
        PriceDetails
    }
}
</script>

<style lang="scss" scoped>
@import './ShippingDetailsPage.scss';
</style>